import { Injectable } from '@angular/core';
import { AuthService } from '@klickdata/core/auth';
import { ConfigService } from '@klickdata/core/config';
import { HttpErrorService, PaginatorResponse, RequestBuilderService, ResponseData } from '@klickdata/core/http';
import { ResourceStaffRoles } from '@klickdata/core/resource/src/types.enum';
import { ImportTemplate, ImportTemplateData } from 'apps/klickdata/src/app/admin/accounts/imports/core/import-template';
import { DatatableHttpService } from 'apps/klickdata/src/app/shared/datatable/datatable-http.service';
import { Observable, of } from 'rxjs';
import { catchError, first, map, share, shareReplay } from 'rxjs/operators';
import { UserMessageData } from './user-message/user-message.model';
import { UserRoleService } from './user-role/user-role.service';
import { User, UserData } from './user.model';

export interface MessageSent {
    sent: boolean;
    receivers: UserData[];
}

@Injectable({
    providedIn: 'root',
})
export class UserService implements DatatableHttpService {
    protected resourceUrl: string;
    protected usersLogUrl: string;
    protected messageUrl: string;
    protected userActivationUrl: string;
    protected importTemplatesUrl: string;
    public customer_id: Observable<number>;
    private authorsMap = new Map<number, Observable<User>>();

    constructor(
        protected configService: ConfigService,
        protected builder: RequestBuilderService,
        protected auth: AuthService,
        protected error: HttpErrorService,
        protected roleService: UserRoleService
    ) {
        this.resourceUrl = `${configService.config.apiUrl}users`;
        this.usersLogUrl = `${configService.config.apiUrl}users/stats/log`;
        this.userActivationUrl = `${configService.config.apiUrl}auth/user`;
        this.messageUrl = `${configService.config.apiUrl}messages`;
        this.importTemplatesUrl = `${this.configService.config.apiUrl}import-templates`;

        this.customer_id = this.auth.getCustomer().pipe(
            first(),
            map((customer) => customer.id)
        );
    }

    /**
     * Fetch all contacts
     */
    public getUsers(user_ids?: number[], currentCustomer = true): Observable<User[]> {
        const request = this.builder.get<UserData[]>(this.resourceUrl);
        if (user_ids) {
            request.param('ids', user_ids.join(','));
        }
        if (currentCustomer) {
            request.param('customer', this.customer_id);
        }
        return request
            .request()
            .pipe(map((res: ResponseData<UserData[]>) => res.data.map((data) => this.mapUser(data))));
    }

    /**
     * Fetch a customers contacts.
     */
    public getCustomerUsers(data?: {
        customerId?: number;
        term: string;
        limit?: number;
        role?: ResourceStaffRoles;
    }): Observable<User[]> {
        const req = this.builder.get<UserData[]>(this.resourceUrl);
        // req.param('customer', data?.customerId ? data.customerId : this.customer_id);

        if (data?.term && data?.term?.length) {
            req.param('query', data.term);
        }
        if (data?.role) {
            req.param('staff', data.role);
        }
        if (data?.customerId) {
            req.param('customer', data.customerId);
        }
        if (data?.limit) {
            req.limit(data.limit);
        }

        return req.request().pipe(map((res) => res.data.map((data) => this.mapUser(data))));
    }

    public getAuthorsAndInstructors(query?: string): Observable<User[]> {
        const req = this.builder.get<UserData[]>(this.resourceUrl);
        if (query && query.length) {
            req.param('query', query);
        }
        return req
            .param('customer', this.customer_id)
            .request()
            .pipe(map((res) => res.data.map((data) => this.mapUser(data))));
    }

    public getLightMasterUsers(params: {
        query?: string;
        limit?: number;
        role?: ResourceStaffRoles;
        userRole?: string;
        eager?: string[];
        ids?: number[];
    }): Observable<User[]> {
        const req = this.builder.get<UserData[]>(this.resourceUrl);
        if (params?.query && params?.query?.length) {
            req.param('query', params.query);
        }
        if (params?.limit) {
            req.param('limit', params.limit);
        }
        if (params?.role) {
            req.param('staff', params.role);
        }
        if (params?.userRole) {
            req.param('role', params.userRole);
        }

        return req
            .param('light', 'true')
            .putEager(params.eager)
            .ids(params.ids)
            .request()
            .pipe(map((res) => res.data.map((data) => this.mapUser(data))));
    }

    public getUsersRelatedToMessages(query?: string): Observable<User[]> {
        const req = this.builder.get<UserData[]>(`${this.messageUrl}/search/users`);
        if (query && query.length) {
            req.param('query', query);
        }
        return req
            .param('customer', this.customer_id)
            .request()
            .pipe(map((res) => res.data.map((data) => this.mapUser(data))));
    }

    public getAuthors(scope: number, query?: string, limit?: number): Observable<User[]> {
        return this.builder
            .get<UserData[]>(this.resourceUrl)
            .query(query)
            .param('light', 1)
            .param('scope', scope)
            .limit(limit)
            .request()
            .pipe(map((res) => res.data.map((data) => this.mapUser(data))));
    }

    public getInstructors(scope: number, query?: string, limit?: number): Observable<any[]> {
        return this.builder
            .get<UserData[]>(this.resourceUrl)
            .param('educator', query)
            .param('scope', scope)
            .limit(limit)
            .request()
            .pipe(map((res) => res.data));
    }

    public getUserActivation(UserId: number): Observable<string> {
        return this.builder
            .get<{ activation_link: string }>(`${this.userActivationUrl}/${UserId}/activate-link`)
            .request()
            .pipe(map((res) => res.data.activation_link));
    }

    public getFavAuthorsAndInstructorsPerUser(user_id: number): Observable<User[]> {
        const req = this.builder.get<UserData[]>(this.resourceUrl);
        req.param('customer', this.customer_id);
        // .param('user', user_id)
        return req.request().pipe(map((res) => res.data.map((data) => this.mapUser(data))));
    }

    public updateUserfavAuthAndInst(authors: UserData[], user_id: number): Observable<User[]> {
        return this.builder
            .post<UserData[]>(`${this.resourceUrl}/${user_id}`, authors)
            .request()
            .pipe(map((res) => res.data.map((data) => this.mapUser(data))));
    }

    /**
     * TODO: To be put in import service after update import service
     * @param ids
     * @returns
     */

    public getImportTemplates(ids?: number[]): Observable<ImportTemplate[]> {
        const req = this.builder.get<ImportTemplateData[]>(this.importTemplatesUrl);

        if (ids) {
            req.param('ids', ids.join(','));
        } else {
            req.param('customerOrPublic', this.customer_id);
        }

        return req.request().pipe(map((res) => res.data.map((item) => new ImportTemplate(item))));
    }

    /**
     * Get klickdata contacts.
     *
     * @param customerId string
     */
    public getSuperAdmins(extra?: { [key: string]: any }): Observable<User[]> {
        return this.builder
            .get<UserData[]>(this.resourceUrl)
            .param('masters', 'true')
            .putParam(extra)
            .request()
            .pipe(
                map((res) => {
                    return res.data.map((data) => this.mapUser(data));
                })
            );
    }

    public getCustomerAdministrators(customerId?: number): Observable<User[]> {
        return this.builder
            .get<UserData[]>(this.resourceUrl)
            .param('customer', customerId ? customerId : this.customer_id)
            .param('customerAdmin', 'true')
            .request()
            .pipe(
                map((res) => {
                    return res.data.map((data) => this.mapUser(data));
                })
            );
    }

    /**
     * Fetch a user
     */
    public getUser(id: number | string, eager?: string[]): Observable<User> {
        return this.builder
            .get(`${this.resourceUrl}/${id}`)
            .putEager(eager)
            .request()
            .pipe(map((res) => this.mapUser(res.data)));
    }

    public getMe(): Observable<User> {
        return this.auth.getUser();
    }

    public datatable(
        query: string,
        page?: number | null,
        sort?: string,
        dir?: string,
        selection?: number[],
        params = {},
        limit?: number
    ): Observable<PaginatorResponse<User[]>> {
        const builder = this.builder.get<UserData[]>(this.resourceUrl);
        for (const i in params) {
            if (params.hasOwnProperty(i)) {
                if (i === 'customer' && typeof params['customer'] !== 'number') {
                    builder.param('customer', this.customer_id);
                } else {
                    builder.param(i, params[i]);
                }
            }
        }

        if (query) {
            builder.param('query', query);
        }

        builder.page(page);
        builder.limit(limit);

        if (sort) {
            builder.param('sort', sort);
        }

        if (dir) {
            builder.param('dir', dir);
        }

        if (selection) {
            builder.param('ids', selection);
        }

        if (limit) {
            builder.limit(limit);
        }

        return builder.request().pipe(
            map((res: PaginatorResponse<UserData[]>) => {
                return {
                    data: res.data.map((data) => this.mapUser(data)),
                    notify: res.notify,
                    paginator: res.paginator,
                };
            })
        );
    }

    public create(userData: UserData, eager?: string | string[]): Observable<User> {
        return this.builder
            .post<UserData>(this.resourceUrl, userData)
            .putEager(eager)
            .request()
            .pipe(map((res) => this.mapUser(res.data)));
    }

    public update(userData: UserData, eager?: string | string[]): Observable<User> {
        return this.builder
            .put<UserData>(`${this.resourceUrl}/${userData.id}`, userData)
            .param('customer', userData.customer_id)
            .putEager(eager)
            .request()
            .pipe(map((res) => this.mapUser(res.data)));
    }

    public destroy(userId: number): any {
        return this.builder
            .delete<any>(`${this.resourceUrl}/${userId}`)
            .request()
            .pipe(
                map((res) => res.data),
                catchError((err) => this.error.handle(err))
            );
    }
    public destroyMultipleUsers(userIds: number[]): any {
        return this.builder
            .delete<any>(`${this.resourceUrl}/${userIds}`)
            .request()
            .pipe(
                map((res) => res.data),
                catchError((err) => this.error.handle(err))
            );
    }

    public restore(userId: number): any {
        return this.builder
            .put<UserData>(`${this.resourceUrl}/${userId}/restore`, null)
            .request()
            .pipe(map((res) => this.mapUser(res.data)));
    }
    public restoreMultipleUsers(userIds: number[]): any {
        return this.builder
            .put<UserData[]>(`${this.resourceUrl}/${userIds}/restore`, null)
            .request()
            .pipe(map((res) => this.mapUsers(res.data)));
    }

    public send(message: UserMessageData): Observable<MessageSent> {
        if (message?.users_attach?.length === 1) {
            return this.builder
                .post<MessageSent>(`${this.resourceUrl}/${message.users_attach[0]}/send`, message)
                .request()
                .pipe(map((res) => res.data));
        } else {
            return this.sendActivationPatch(message);
        }
    }

    public sendMailToMultipleUsers(message: UserMessageData): Observable<MessageSent> {
        return this.builder
            .post<MessageSent>(`${this.resourceUrl}/${message.users_attach.join(',')}/send`, message)
            .request()
            .pipe(map((res) => res.data));
    }

    private sendActivationPatch(message: UserMessageData): Observable<MessageSent> {
        return this.builder
            .post<MessageSent>(`${this.resourceUrl}/activate/send`, message)
            .request()
            .pipe(map((res) => res.data));
    }

    /**
     * Maps UserData to a User and
     * assigns observables.
     */
    protected mapUser(userData: UserData): User {
        const user = new User(userData);
        // Role gets fetched when needed and results are cached.
        user.role = this.roleService.getUserRole(user.role_value).pipe(shareReplay());
        user.createdBy = user.creator ? of(new User(user.creator)) : this.getAuthor(user.created_by);
        return user;
    }

    /**
     * Maps data users into user objects.
     */
    protected mapUsers(data: UserData[]): User[] {
        return Array.isArray(data) ? data.map((item) => this.mapUser(item)) : [data];
    }

    /**
     * count users within customer in time period.
     * @param begin start date
     * @param end end date
     */
    public userCount(begin: string, end: string, groups: number[]): Observable<number> {
        const req = this.builder.get<number>(`${this.resourceUrl}/count`);
        req.param('customer', this.customer_id);
        req.param('begin', begin);
        req.param('end', end);
        if (groups && groups.length) {
            req.param('groups', groups.join(','));
        }
        return req.request().pipe(map((response) => response.data));
    }

    public getAuthor(author_id: number): Observable<User> {
        let author = this.authorsMap.get(author_id);
        if (author) {
            return author;
        }
        author = this.getUser(author_id).pipe(
            share(),
            catchError(() => of(new User({ fname: 'Anonymous', lname: '' })))
        );
        this.authorsMap.set(author_id, author);
        return author;
    }
}
