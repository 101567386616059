import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { GroupData, GroupService } from '@klickdata/core/group';
import { SideNaveData } from '@klickdata/core/mobile';
import { SideNaveActionsTypes, SideNaveResponseData } from '@klickdata/core/mobile/src/mobile.service';
import { User, UserService } from '@klickdata/core/user';
import { OnDestroyHandler } from 'apps/klickdata/src/app/shared/onDestroy-handler/onDestroy-handler';
import { debounceTime, distinctUntilChanged, filter, Observable, of, share, switchMap, takeUntil } from 'rxjs';

@Component({
    selector: 'app-menu-side-create-group',
    templateUrl: './menu-side-create-group.component.html',
    styleUrls: ['./menu-side-create-group.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuSideCreateGroupComponent extends OnDestroyHandler implements AfterViewInit {
    @Input() public navData: SideNaveData;
    @Output() onClose: EventEmitter<SideNaveResponseData> = new EventEmitter();
    public groupForm: FormGroup;
    public loading$: Observable<boolean>;
    public userSearch$: Observable<User[]>;
    public userControl = new FormControl();
    public teamManagers: User[] = [];

    constructor(
        protected fb: FormBuilder,
        protected groupService: GroupService,
        protected userService: UserService,
        protected cdr: ChangeDetectorRef
    ) {
        super();
        this.buildForm();
    }
    ngAfterViewInit(): void {
        this.userSearch$ = this.userControl.valueChanges.pipe(
            filter((term: string) => typeof term === 'string' && term != ''),
            debounceTime(300),
            distinctUntilChanged(),
            share(),
            switchMap((term) =>
                term.trim()
                    ? this.userService.getCustomerUsers({
                          term: term,
                          limit: 20,
                          customerId: this.navData.data.customerId,
                      })
                    : of(<User[]>[])
            )
        );
    }
    buildForm() {
        this.groupForm = this.fb.group({
            name: ['', Validators.required],
            expiration: [null],
        });
    }
    public get isTeam(): boolean {
        return this.navData?.data?.isTeam;
    }
    public selected(auto: MatAutocomplete, event: MatAutocompleteSelectedEvent) {
        this.userControl.patchValue(event.option.value.name);
        this.userControl.disable();
        this.teamManagers = [event.option.value];
        auto.options.reset([]);
        this.cdr.markForCheck();
    }
    onRemoveManager() {
        this.userControl.patchValue('');
        this.userControl.enable();
        this.teamManagers = [];
        this.cdr.markForCheck();
    }
    public submit(editGroup?: boolean): void {
        this.loading$ = of(true);
        this.groupService
            .create(this.prepareGroupData())
            .pipe(takeUntil(this.destroy))
            .subscribe((group) => {
                this.loading$ = of(false);
                this.groupForm.reset();
                this.onClose.emit({
                    action: SideNaveActionsTypes.POSITIVE,
                    data: {
                        group: group,
                        editGroup: editGroup,
                        type: this.navData.data.type,
                    },
                });
            });
    }
    private prepareGroupData(): GroupData {
        const data = this.groupForm.value;
        data.customer_id = this.navData.data.customerId;
        if (!!this.navData.data.userIds?.length) {
            data.users_attach = this.navData.data.userIds;
        }
        if (this.isTeam) {
            data.team = true;
            if (this.teamManagers?.length) {
                data.admin_ids = this.teamManagers.map((manager) => manager.id);
            }
        }
        return data;
    }
}
