import { Injectable } from '@angular/core';
import { AuthService } from '@klickdata/core/auth/src/token/auth.service';
import { ConfigService } from '@klickdata/core/config/src/config.service';
import { CustomerService } from '@klickdata/core/customer/src/customer.service';
import { HttpErrorService } from '@klickdata/core/http/src/error/http-error.service';
import { RequestBuilderService } from '@klickdata/core/http/src/request/request-builder.service';
import { ResponseData } from '@klickdata/core/http/src/responce/responce';
import { User } from '@klickdata/core/user/src/user.model';
import { UserService } from '@klickdata/core/user/src/user.service';
import { Observable, of } from 'rxjs';
import { catchError, first, map, share, switchMap } from 'rxjs/operators';
import { Group, GroupData } from './group.model';

@Injectable()
export class GroupService {
    protected resourceUrl: string;
    protected user_id: Observable<number>;
    public customer_id: Observable<number>;

    /**
     * Initialize service and create resourceUrl observable.
     */
    constructor(
        protected auth: AuthService,
        protected builder: RequestBuilderService,
        protected config: ConfigService,
        protected customerService: CustomerService,
        protected userService: UserService,
        protected error: HttpErrorService
    ) {
        this.resourceUrl = `${this.config.config.apiUrl}groups`;

        this.user_id = this.auth.getUser().pipe(
            first(),
            map((user) => user.id)
        );

        this.customer_id = this.auth.getCustomer().pipe(
            first(),
            map((customer) => customer.id)
        );
    }

    /**
     * Fetch groups from useCustomer.
     */
    public getGroups(page = 0): Observable<Group[]> {
        return this.builder
            .get<GroupData[]>(this.resourceUrl)
            .page(page)
            .request()
            .pipe(map((res) => res.data.map((data) => new Group(data))));
    }

    /**
     * Fetch an array of groups based on a array of ids.
     */
    public getGroupsByIds(ids: number[]): Observable<Group[]> {
        const req = this.builder.get<GroupData[]>(`${this.resourceUrl}`);
        req.param('ids', ids);
        req.param('customer', this.customer_id);

        return req.request().pipe(
            map((res) => {
                return res.data.map((data) => this.setupGroup(data));
            })
        );
    }

    public getCustomerGroups(query?: string, limit?: number): Observable<Group[]> {
        const req = this.builder.get<GroupData[]>(this.resourceUrl);
        req.param('customer', this.customer_id);
        if (query) {
            req.param('query', query);
        }
        req.limit(limit);
        return req.request().pipe(map((res) => res.data.map((data) => this.setupGroup(data))));
    }

    public getGroupsByParams(params: { property: string; value: any }[]): Observable<Group[]> {
        const req = this.builder.get<GroupData[]>(this.resourceUrl);
        params.forEach((param) => {
            if (param.property && param.value) {
                req.param(param.property, param.value);
            }
        });
        return req.request().pipe(map((res) => res.data.map((data) => this.setupGroup(data))));
    }

    public getCustomerGroupsById(customerId: string | number): Observable<Group[]> {
        return this.builder
            .get<GroupData[]>(this.resourceUrl)
            .param('customer', customerId)
            .request()
            .pipe(map((res) => res.data.map((data) => this.setupGroup(data))));
    }

    public getAdministratorGroups(): Observable<Group[]> {
        return this.builder
            .get<GroupData[]>(this.resourceUrl)
            .param('administrator', this.user_id)
            .request()
            .pipe(map((res) => res.data.map((data) => new Group(data))));
    }

    /**
     * Fetch a group.
     */
    public getGroup(id: number, eager?: string | string[]): Observable<Group> {
        return this.builder
            .get<GroupData>(`${this.resourceUrl}/${id}`)
            .putEager(eager)
            .request()
            .pipe(map((res) => this.setupGroup(res.data)));
    }

    public getUserGroup(userId: number) {
        return this.builder
            .get<GroupData[]>(this.resourceUrl)
            .param('user', `${userId}`)
            .request()
            .pipe(map((res) => res.data.map((item) => new Group(item))));
    }

    public getGroupAdminGroups(userId: number) {
        return this.builder
            .get<GroupData[]>(this.resourceUrl)
            .param('groupAdmin', `${userId}`)
            .request()
            .pipe(map((res) => res.data.map((item) => new Group(item))));
    }

    public getCustomerResourceGroups(resource_id: number): Observable<Group[]> {
        return this.builder
            .get<GroupData[]>(this.resourceUrl)
            .param('resource', `${resource_id}`)
            .param('customer', this.customer_id)
            .request()
            .pipe(map((res) => res.data.map((item) => new Group(item))));
    }

    /**
     * Create new group
     */
    create(group: GroupData, eager?: string | string[]): Observable<Group> {
        return this.builder
            .post<GroupData>(this.resourceUrl, group)
            .putEager(eager)
            .request()
            .pipe(map((res) => this.setupGroup(res.data)));
    }

    /**
     * Remove group
     */
    destroy(group: Group): Observable<ResponseData<null>> {
        return this.builder
            .delete<null>(`${this.resourceUrl}/${group.id}`)
            .request()
            .pipe(catchError((err) => this.error.handle(err)));
    }

    /**
     * Add user to group
     */
    public addUser(group: Group, user: User): Observable<Group> {
        return this.builder
            .put<GroupData>(`${this.resourceUrl}/${group.id}/add-user`, null)
            .request()
            .pipe(map((res) => this.setupGroup(res.data)));
    }

    public update(group: GroupData, eager?: string | string[]): Observable<Group> {
        return this.builder
            .put<GroupData>(`${this.resourceUrl}/${group.id}`, group)
            .param('customer', group.customer_id)
            .putEager(eager)
            .request()
            .pipe(map((res) => this.setupGroup(res.data)));
    }

    protected setupGroup(groupData: GroupData): Group {
        const group = new Group(groupData);
        group.customer = this.customerService.getCustomer(group.customer_id);
        if (groupData.admins) {
            group.admins_names = groupData.admins.map((admin) => admin.name);
        }

        // handle created_by may return as user obj
        if (group.created_by) {
            if (typeof group.created_by === 'object') {
                group.created_by = group.created_by.id;
            }
            group.creator = this.userService.getUser(group.created_by).pipe(
                share(),
                catchError(() => of(new User({ fname: 'Anonymous', lname: '' })))
            );
        }
        if (group.user_ids) {
            group.users$ = this.userService.getUsers(group.user_ids);
        }
        return group;
    }

    protected mapGroups(data: GroupData[]): Group[] {
        return data.map((item) => this.setupGroup(item));
    }

    /**
     * count group within customer in time period.
     * @param begin start date for counting
     * @param end end date for counting
     */
    public groupCount(begin: string, end: string, groups: number[]): Observable<number> {
        const req = this.builder.get<number>(`${this.resourceUrl}/count`);
        req.param('customer', this.customer_id);
        req.param('begin', begin);
        req.param('end', end);
        if (groups && groups.length) {
            req.param('groups', groups.join(','));
        }
        return req.request().pipe(map((response) => response.data));
    }
}
