import { Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { ExpandAnimation } from '@klickdata/core/animations/expand.animation';
import { LoggerService } from '@klickdata/core/application';
import { ConfigService } from '@klickdata/core/config';
import { Utils } from '@klickdata/core/util';
import { BehaviorSubject, Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { MediaPlayerComponent } from '../media-player/media-player.component';
import { MediaType } from '../media-type';
import { Media } from '../media.model';
import { S3MediaService } from '../s3-media.service';
import { Location } from '@angular/common';
import { MediaService } from '../media.service';

@Component({
    selector: 'app-media-view',
    templateUrl: './media-view.component.html',
    styleUrls: ['./media-view.component.scss'],
    animations: [ExpandAnimation],
})
export class MediaViewComponent implements OnDestroy {
    @ViewChild(MediaPlayerComponent) player: MediaPlayerComponent;
    public isDefinedMedia: boolean;
    @Input() title: string;
    @Input() canDownlaodMedia: boolean;
    @Input() resourceItemTypeValue: string;
    @Input() displayName = true;
    @Input()
    public set articleCode(article_code: string) {
        this.onUpdateMedia(<Media>{
            url: `${this.configService.config.AW_S3_MATERIAL_URL}/courses/${article_code}/filer/1v.mp4`,
            type: 'video/mp4',
            provider: 'html5',
            mediaType: MediaType.VIDEO,
        });
    }

    @Input()
    public set media_id(media_id: string) {
        this._media_id = media_id;
        if (media_id) {
            if (this.resourceItemTypeValue) {
                this.onUpdateMedia(new Media({ type: this.resourceItemTypeValue, url: media_id }));
            } else {
                const media = Utils.getMedia(media_id);
                if (media) {
                    this.onUpdateMedia(media);
                } else if (this.s3MediaService.isS3Material(media_id)) {
                    this.s3MediaService
                        .readMetadataFromS3(media_id)
                        .pipe(first(), takeUntil(this.destroy))
                        .subscribe((media) => this.onUpdateMedia(media));
                    this.isS3Media = true;
                } else if (this.s3MediaService.isS3Media(media_id)) {
                    this.mediaService
                        .getMediaByPath(media_id)
                        .pipe(first(), takeUntil(this.destroy))
                        .subscribe((media) => this.onUpdateMedia(media));
                    this.isS3Media = true;
                } else {
                    this.onUpdateMedia(<Media>{ url: media_id });
                }
            }
        }
    }
    @Input() set media(media: Media) {
        this.onUpdateMedia(media);
        this.isDefinedMedia = Object.values(MediaType).includes(<MediaType>media.mediaType);
        this.isS3Media = true;
    }
    public get media_id(): string {
        return this._media_id;
    }
    private _media_id: string;
    public isS3Media: boolean;
    public MediaType = MediaType;
    public media$: BehaviorSubject<Media> = new BehaviorSubject<Media>(null);
    private destroy: Subject<boolean> = new Subject<boolean>();
    public totalPages: number;
    public page = 1;
    public isPDFLoaded = false;
    @Output() mediaCompleted: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() done: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() nextBtnClickedInPDF: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() downloading: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() playerReady: EventEmitter<void> = new EventEmitter<void>();
    @Output() played: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() onMediaUpdated: EventEmitter<Media> = new EventEmitter<Media>();

    constructor(
        protected s3MediaService: S3MediaService,
        protected mediaService: MediaService,
        protected configService: ConfigService,
        protected elementRef: ElementRef,
        protected location: Location,
        protected logger: LoggerService
    ) {
        (window as any).pdfWorkerSrc = this.location.prepareExternalUrl('/assets/js/pdf.worker.min.js');
    }
    onUpdateMedia(media: Media) {
        this.media$.next(media);
        this.onMediaUpdated.emit(media);
    }
    /**
     * Handle PDF viewer
     */
    nextPage() {
        this.page += 1;
        this.nextBtnClickedInPDF.emit(true);
        this.checkFinishingPDF();
    }

    /**
     * Get element by class not the best practice however you get ElementRef using Angular API.
     * Or style using API provided tool.
     * @todo
     * 1. Upgrade n2-pdf-viewer into latest version (v9)
     * 2. handle CDN issue or keep using pdfWorkerSrc
     * 3. handle style using Angular/Library API.
     * 4. Fix style width/height need to be define with latest version
     * 5. (page-rendered) was not working with latest version need more digging.
     */
    pageRendered() {
        this.elementRef.nativeElement.getElementsByClassName('ng2-pdf-viewer-container')[0].style.display = 'contents';
    }
    previousPage() {
        this.page -= 1;
        this.checkFinishingPDF();
    }

    public iframeLoaded() {
        this.mediaCompleted.emit(true);
    }

    afterLoadComplete(pdfData: any) {
        this.totalPages = pdfData.numPages;
        this.isPDFLoaded = true;
        this.checkFinishingPDF();
    }

    checkFinishingPDF() {
        this.mediaCompleted.emit(this.page === this.totalPages);
    }

    public ngOnDestroy() {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }

    /**
     * OnMessage event
     * @param event - The message sent from iframe
     */
    @HostListener('window:message', ['$event'])
    public onMessage(event: MessageEvent) {
        if (event.data === 'completed') {
            this.done.emit(true);
        }
    }

    public openFileByLink(url: string) {
        window.open(url, '_blank');
        this.downloading.emit(true);
    }
}
