import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SideNaveData } from '@klickdata/core/mobile';
import { SideNaveActionsTypes, SideNaveResponseData } from '@klickdata/core/mobile/src/mobile.service';
import { Utils } from '@klickdata/core/util';
import { OnDestroyHandler } from 'apps/klickdata/src/app/shared/onDestroy-handler/onDestroy-handler';
import * as moment from 'moment';
import { startWith, takeUntil } from 'rxjs';

@Component({
    selector: 'app-menu-side-update-recurring',
    templateUrl: './menu-side-update-recurring.component.html',
    styleUrls: ['./menu-side-update-recurring.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuSideUpdateRecurringComponent extends OnDestroyHandler implements OnInit {
    @Input() public navData: SideNaveData;
    @Output() onClose: EventEmitter<SideNaveResponseData> = new EventEmitter();
    public recurringForm: FormGroup;
    recurringStr: string;

    constructor(protected fb: FormBuilder) {
        super();

        this.recurringForm = this.fb.group({
            recurring: [1, [Validators.required, Validators.min(1)]], // Default value of 1 day, and min of 1 to prevent negatives
            start_date: [moment()],
        });

        // Set up a listener for value changes on the form control
        this.recurringForm
            .get('recurring')
            ?.valueChanges.pipe(takeUntil(this.destroy), startWith(1))
            .subscribe((days: number) => {
                this.recurringStr = Utils.daysToString(days);
            });
    }

    ngOnInit(): void {
        if (this.navData.data.resource?.recurring) {
            this.recurringForm.patchValue(this.navData.data.resource.recurring);
        }
    }

    // Handle selection change from the dropdown
    onRecurringSelectChange(event: any) {
        this.recurringStr = event.value;
        if (this.recurringStr !== 'custom') {
            // Default number of days for the selected type (e.g., daily=1, weekly=7, etc.)
            const days = Utils.stringToDays(this.recurringStr);
            this.recurringForm.get('recurring')?.setValue(days);
        } else {
            // Let the user manually enter the number of days for "Custom"
            this.recurringForm.get('recurring')?.reset();
        }
    }

    public submitRecurring() {
        this.onClose.emit({
            action: SideNaveActionsTypes.POSITIVE,
            data: {
                value: this.recurringForm.value,
                type: this.navData.data.type,
            },
        });
    }
}
