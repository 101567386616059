<div class="data-wrap" fxLayout="column">
    <div class="title" fxLayoutGap="5px" fxLayout="row wrap" fxLayoutAlign="start center">
        <mat-icon class="red">circle</mat-icon><span>Update "wastZQ"</span>
    </div>
    <form [formGroup]="recurringForm" fxLayoutGap="1em" fxFlex="0 0 100%" fxLayout="column">
        <!-- <div fxLayoutGap="4px" fxLayout="column" class="w-100 input-field-parent select">
            <span i18n>Update recurring period</span>
            <mat-form-field class="role-field">
                <mat-select fxFlex="1 1 100%" formControlName="recurring">
                    <mat-option value="daily" i18n>Daily</mat-option>
                    <mat-option value="weekly" i18n>Weekly</mat-option>
                    <mat-option value="monthly" i18n>Monthly</mat-option>
                    <mat-option value="quarterly" i18n>Quarterly</mat-option>
                    <mat-option value="half_yearly" i18n>Half-Yearly</mat-option>
                    <mat-option value="yearly" i18n>Yearly</mat-option>
                </mat-select>
            </mat-form-field>
        </div> -->
        <div fxLayoutGap="4px" fxLayout="column" class="w-100 input-field-parent deadline">
            <span i18n>Update completion date</span>
            <mat-form-field
                appearance="outline"
                floatLabel="never"
                class="w-100"
                fxFlex="grow"
                fxFlex="1 1 100%"
                fxFlex.lt-md="1 1 100%"
            >
                <mat-label i18n>Enter start date</mat-label>
                <input
                    matInput
                    [matDatepicker]="deadlineDatepicker"
                    (focus)="deadlineDatepicker.open()"
                    formControlName="start_date"
                />
                <mat-datepicker-toggle matIconSuffix [for]="deadlineDatepicker"></mat-datepicker-toggle>
                <mat-datepicker #deadlineDatepicker></mat-datepicker>
            </mat-form-field>
        </div>
        <div fxFlex="1 0 100%" fxLayout="row wrap" fxLayoutAlign="end center" fxLayoutGap=".5em" class="mt-1">
            <button class="cancelBtn" mat-raised-button (click)="onClose.emit()" i18n>Cancel</button>
            <button
                mat-raised-button
                color="primary"
                [disabled]="recurringForm.invalid && recurringForm.touched"
                (click)="submitRecurring()"
                i18n
            >
                Done
            </button>
        </div>
    </form>
</div>
