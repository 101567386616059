<div class="data-wrap" fxLayout="column">
    <div class="title" fxLayoutGap="5px" fxLayout="row wrap" fxLayoutAlign="start center">
        <mat-icon>event_repeat</mat-icon><span>{{ navData.data.title }}</span>
    </div>
    <form [formGroup]="recurringForm" fxLayoutGap="1em" fxFlex="0 0 100%" fxLayout="column">
        <!-- Repeats Dropdown -->
        <div fxLayoutGap="4px" fxLayout="column" class="w-100 input-field-parent select">
            <span i18n>Repeats</span>
            <mat-form-field class="role-field">
                <mat-select
                    fxFlex="1 1 100%"
                    [value]="recurringStr"
                    (selectionChange)="onRecurringSelectChange($event)"
                >
                    <mat-option value="daily" i18n>Daily</mat-option>
                    <mat-option value="weekly" i18n>Weekly</mat-option>
                    <mat-option value="monthly" i18n>Monthly</mat-option>
                    <mat-option value="quarterly" i18n>Quarterly</mat-option>
                    <mat-option value="half_yearly" i18n>Half-Yearly</mat-option>
                    <mat-option value="yearly" i18n>Yearly</mat-option>
                    <mat-option value="custom" i18n>Custom</mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <!-- Repeat every X days -->
        <div fxLayoutGap="4px" fxLayout="column" class="w-100 input-field-parent">
            <span i18n>Repeat every</span>
            <mat-form-field appearance="outline">
                <input matInput type="number" formControlName="recurring" placeholder="Enter number of days" />
            </mat-form-field>
        </div>

        <!-- Start date -->
        <div fxLayoutGap="4px" fxLayout="column" class="w-100 input-field-parent deadline">
            <span i18n>Start date</span>
            <mat-form-field appearance="outline" floatLabel="never" class="w-100">
                <mat-label i18n>Enter start date</mat-label>
                <input matInput [matDatepicker]="startDatePicker" formControlName="start_date" />
                <mat-datepicker-toggle matIconSuffix [for]="startDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #startDatePicker></mat-datepicker>
            </mat-form-field>
        </div>

        <!-- Ends section -->
        <!-- <div fxLayoutGap="4px" fxLayout="column" class="w-100 input-field-parent">
            <span i18n>Ends</span>
            <mat-radio-group formControlName="ends">
                <mat-radio-button value="never" i18n>Never</mat-radio-button>
                <mat-radio-button value="after" i18n>
                    After <input type="number" formControlName="occurrences" placeholder="Occurrences" />
                </mat-radio-button>
                <mat-radio-button value="on_date" i18n>
                    On
                    <input matInput [matDatepicker]="endDatePicker" formControlName="end_date" />
                    <mat-datepicker-toggle matIconSuffix [for]="endDatePicker"></mat-datepicker-toggle>
                    <mat-datepicker #endDatePicker></mat-datepicker>
                </mat-radio-button>
            </mat-radio-group>
        </div> -->

        <!-- Action buttons -->
        <div fxFlex="1 0 100%" fxLayout="row wrap" fxLayoutAlign="end center" fxLayoutGap=".5em" class="mt-1">
            <button class="cancelBtn" mat-raised-button (click)="onClose.emit()" i18n>Cancel</button>
            <button
                mat-raised-button
                color="primary"
                [disabled]="recurringForm.invalid && recurringForm.touched"
                (click)="submitRecurring()"
                i18n
            >
                Done
            </button>
        </div>
    </form>
</div>
