import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SideNaveData } from '@klickdata/core/mobile';
import { SideNaveActionsTypes, SideNaveResponseData } from '@klickdata/core/mobile/src/mobile.service';
import * as moment from 'moment';

@Component({
    selector: 'app-menu-side-update-comp',
    templateUrl: './menu-side-update-comp.component.html',
    styleUrls: ['./menu-side-update-comp.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuSideUpdateCompComponent implements OnInit {
    @Input() public navData: SideNaveData;
    @Output() onClose: EventEmitter<SideNaveResponseData> = new EventEmitter();
    public recurringForm: FormGroup;

    constructor(protected fb: FormBuilder) {
        this.recurringForm = this.fb.group({
            recurring: ['yearly'],
            start_date: [moment()],
        });
    }
    ngOnInit(): void {
        if (this.navData.data.resource?.recurring?.metadata) {
            this.recurringForm.patchValue(this.navData.data.resource.recurring.metadata);
        }
    }
    public submitRecurring() {
        this.onClose.emit({
            action: SideNaveActionsTypes.POSITIVE,
            data: {
                value: this.recurringForm.value,
                type: this.navData.data.type,
            },
        });
    }
}
