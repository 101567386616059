import {
    AfterContentInit,
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ContentChild,
    Input,
    OnDestroy,
    OnInit,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AuthService, CanComponentDeactivate } from '@klickdata/core/auth';
import { MobileService } from '@klickdata/core/mobile';
import { AppScope, ResourceTypes } from '@klickdata/core/resource';
import { Resource } from '@klickdata/core/resource/src/resource.model';
import { GlobalFilterProperty, SelectFilterOption } from '@klickdata/core/table';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { SelectModelBaseDirective } from '../../select-model-base/select-model-base.component';
import { Utils } from './../../../../../core/util/src/utils';
import { ResourceSelectDatatableService } from './resource-select-datatable.service';

@Component({
    selector: 'app-resource-select',
    templateUrl: './resource-select.component.html',
    styleUrls: ['./resource-select.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [ResourceSelectDatatableService],
})
export class ResourceSelectComponent
    extends SelectModelBaseDirective<Resource>
    implements OnInit, AfterViewInit, CanComponentDeactivate, OnDestroy, AfterContentInit
{
    @Input() accessForm: FormGroup;
    @Input() referenceFilter: string;
    @Input() selectionLabel: string;
    @Input() scope_id: AppScope;
    @Input() customer_id: number;
    @Input() prefLangIds: number[];
    @Input() sync_all_key = 'sync_all_resources';
    @Input() attachKey = 'resources_attach';
    @Input() detachKey = 'resources_detach';
    @ContentChild('tools') tools: TemplateRef<any>;
    public columns = ['select', 'title', 'created_by', 'created_at'];
    GlobalFilterProperty = GlobalFilterProperty;
    ResourceTypes = ResourceTypes;
    AppScope = AppScope;
    public itemSelectedOptions: SelectFilterOption[];
    public isMasterAdmin$: Observable<boolean>;

    constructor(
        public datatableService: ResourceSelectDatatableService,
        protected mobileService: MobileService,
        protected auth: AuthService
    ) {
        super(datatableService, mobileService);
        this.itemSelectedOptions = Utils.getItemSelectedOptions();
        this.isMasterAdmin$ = this.auth.getUser().pipe(
            first(),
            map((user) => user.isMasterAdmin())
        );
    }

    protected refresh(id?: any): void {
        const filters = [{ property: 'scope', items: [this.scope_id] }];
        if (this.referenceFilter) {
            filters.push({ property: this.referenceFilter, items: [id] });
        }
        if (this.customer_id) {
            filters.push({ property: 'assignedOrCustomer', items: [this.customer_id] });
        }
        this.filter?.createOrUpdateWithoutEmitFilter(filters);
    }
    detectIdKeyValueChange() {
        if (this.referenceFilter) {
            super.detectIdKeyValueChange();
        } else {
            this.refresh();
        }
    }
    ngAfterContentInit(): void {
        if (this.tools) {
            this.columns.push('tools');
        }
    }
}
