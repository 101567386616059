<app-table-toolbar color="accent" class="toolbar select-res-toolbar">
    <app-table-filter [cacheScope]="AppScope.RESOURCES" [inGlobalFilter]="true" color="accent" #filter>
        <app-table-search [inGlobalFilter]="true" class="align-space" searchFilterRef></app-table-search>
        <app-tag-chip-search-filter
            [inGlobalFilter]="true"
            [languageChange]="langFilter.filterChange"
            fxFlex="50%"
            fxFlex.lt-md="100%"
            fxLayoutAlign="start"
            tagsFilterRef
        ></app-tag-chip-search-filter>
        <app-category-chip-search-filter
            [inGlobalFilter]="true"
            fxFlex="50%"
            fxFlex.lt-md="100%"
            [scope_id]="scope_id"
            categoriesFilterRef
        ></app-category-chip-search-filter>

        <app-table-date-filter [inGlobalFilter]="true" color="accent" dateFilterRef></app-table-date-filter>

        <app-language-filter
            [inGlobalFilter]="true"
            #langFilter
            fxFlex="0 0 auto"
            fxLayout="row"
            [prefLangIds]="prefLangIds"
            langFilterRef
        ></app-language-filter>

        <app-customer-filter
            *ngIf="isMasterAdmin$ | async"
            [inGlobalFilter]="true"
            class="customer-select"
            fxFlex="1 1 auto"
            customerSearchRef
        ></app-customer-filter>

        <app-select-filter
            [inGlobalFilter]="true"
            class="filter-space"
            label="Filter by selection"
            i18n-label="@@filterBySelection"
            [property]="GlobalFilterProperty.SELECTED"
            [options]="itemSelectedOptions"
            selectedItemsRef
        >
        </app-select-filter>
    </app-table-filter>
    <mat-progress-bar
        class="progress progress-table-loader"
        *ngIf="(dataSource.status | async) === 'loading'"
        mode="indeterminate"
    >
    </mat-progress-bar>
</app-table-toolbar>
<div fxLayoutAlign="start center" fxLayoutGap="10px" class="selection-Info">
    <div fxLayoutAlign="start center" fxLayoutGap="5px" class="main">
        <span class="label">{{ selectionLabel }}</span>
        <span class="total">{{ total }}</span>
    </div>
    <div fxLayoutAlign="start center" fxLayoutGap="5px" class="selected" *ngIf="selected">
        <span i18n="@@selected">Selected:</span>
        {{ selected }}
    </div>
</div>
<mat-table #table [dataSource]="dataSource" matSort matSortActive="title">
    <ng-container matColumnDef="select">
        <mat-header-cell *matHeaderCellDef>
            <mat-checkbox
                (click)="$event.stopPropagation()"
                (change)="masterToggle()"
                [checked]="isChecked()"
                [indeterminate]="isIndeterminate()"
            >
            </mat-checkbox>
        </mat-header-cell>
        <mat-cell *matCellDef="let resource">
            <mat-checkbox
                (click)="$event.stopPropagation()"
                (change)="selectToggle(resource)"
                [checked]="modelChecked(resource)"
            >
            </mat-checkbox>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="title">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@title"> Title </mat-header-cell>
        <mat-cell *matCellDef="let resource">
            <span class="mobile-label" i18n="@@title"> Title </span>
            {{ resource.title }}
        </mat-cell>
    </ng-container>

    <!-- Created by -->
    <ng-container matColumnDef="created_by">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@createdBy"> Created by </mat-header-cell>

        <mat-cell *matCellDef="let resource">
            <span class="mobile-label" i18n="@@createdBy"> Created by </span>
            {{ $any(resource.author$ | async)?.name }}
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="created_at">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@createdAt"> Created at </mat-header-cell>
        <mat-cell *matCellDef="let resource">
            <span class="mobile-label" i18n="@@createdAt"> Created at </span>
            {{ resource.created_at | kdDateFormat : 'YYYY-MM-DD' }}
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="tools">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let resource">
            <ng-container *ngTemplateOutlet="tools; context: { $implicit: resource }"></ng-container>
        </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
    <mat-row *matRowDef="let resource; columns: columns" (click)="selectToggle(resource)"></mat-row>
</mat-table>

<mat-paginator class="res-select-pag" [pageSize]="25" [pageSizeOptions]="[25, 50, 100]"> </mat-paginator>
